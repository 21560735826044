@CHARSET "ISO-8859-1";
/* Section 2
 * --------------------------------------- */
#section-2{
    background: #0798ec;
}
.github-data{
    display: inline-block;
    padding: 5px 10px;
    margin: 2em 5px 0 5px;
    border-radius: 5px;
    width: 25%;
    max-width: 118px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}
.github-data h4{
    font-size: 1.6em;
}
.github-data span{
    font-size: 0.9em;
}
.github-data-1{
    background: #404b5a;
}
.github-data-2{
    background: #2fd069;
}
.github-data-3{
    background: #fc6c7c;
}
.trusted-by-big{
    margin: 2em;
    background-image: url(../imgs/trusted-by-big.png);
    max-width: 633px;
    height: 118px;
    margin: 1em auto 0 auto;
}
.compatible{
    margin: 2em;
    background-image: url(../imgs/compatible.png);
    width: 80%;
    max-width: 444px;
    height: 78px;
    margin: 1em auto 0 auto;
}
.bg-image{
    background-size: 93% auto;
    background-repeat: no-repeat;
    background-position: 50%;
}
.slide{
    position: relative;
}
.sponsors{
    font-size: 14px;
    width: 238px;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 12px;
    text-align: left;
}
.sponsors a{
    background-image: url(../imgs/sponsors3.png);
    width: 44px;
    background-position: -50px 7px;
    height: 51px;
    display: inline-block;
    background-repeat: no-repeat;
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.sponsors a:nth-child(3){
    background-position: 0 7px;
}
.sponsors a:nth-child(4){
    background-position: -100px 7px;
}
.sponsors a:nth-child(5){
    background-position: -150px 7px;
}
.sponsors a:nth-child(6){
    background-position: -200px 7px;
}
.fp-slidesNav{
    width: 400px;
}
@media screen and (max-width: 550px) {
  .sponsors{
    display: none;
  }
}

/* Section 3
 * --------------------------------------- */
#section-3{
    background: #fc6c7c;
}
#section-3 .intro{
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 50%;
    padding: 0 3em;
}
#section-3 .intro p{
    width: 100%;
    max-width: 100%;
    padding: 0;
}
.shell{
    width: calc(50% - 54px);
    display: inline-block;
    background-color: #173549;
    border-radius: 5px 5px 0 0;
    text-align: left;
    margin-right: 54px;
}
.shell-header{
    background: #e5e4e4;
    padding: 0 7px;
    position: relative;
}
.shell-header-actions{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    box-shadow: 0px 0px 10px 1px;
    display: inline-block;
}
.shell-demo{
    position: absolute;
    right: 15px;
    top: 13px;
    color: #173549;
}
.shell-demo:hover{
    text-decoration: underline;
    float: right;
}
.shell-header-close{
    background: red;
}
.shell-header-minimize{
    background: yellow;
}
.shell-header-maximize{
    background: green;
}
.shell-tab{
    padding: 5px 10px;
    display: inline-block;
    padding: 14px 10px;
}
.shell-tab{
    color: #173549;
}
.shell-tab.active{
    background: #fff;
    margin: 0;
}
.shell-tab:hover{
    color: #000;
}
.shell-tab-content{
    display: none;
}
.shell-tab-content.active{
    display: block;
}
figure{
    margin: 0;
}
.shell-body{
    margin: 2.18em;
    font-size: 1.4em;
    overflow-x: scroll;
}

/* Section 4
 * --------------------------------------- */
#section-4{
    background-color: #fec401;
    background-image: url(../imgs/touch-responsive.jpg);
    background-position: 50% 100%;
    background-repeat: no-repeat;

}

/* Language
 * --------------------------------------- */
.languages{
    display: none;
    padding: 46px 0 15px 0;
    position: relative;
    margin-top: -46px;
    z-index: -1;
}
.language.active .languages{
    display: block;
}
.language.active .language-current{
    color: #004f69;
}
.language.active .language-triangle{
    fill: #004f69;
}

/* Submenu
 * --------------------------------------- */
.submenu{
    background: #fff;
    border-radius: 15px;
}
.submenu i{
    width: 17px;
    height: 17px;
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
    background-size: contain;
}
#menu .submenu a,
.submenu a{
    padding: 6px 15px;
    display: block;
    color: #004f69;
}
.submenu a:hover{
    background: #f2f2f2;
}

/* Plugins
 * --------------------------------------- */
.plugins{
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
    transform-origin: 50% 0%;
    top: 34px;
    padding: 15px 0;
    width: 235px;
    z-index: 999;
}
#menu .plugins li{
    display: block;
}
.menu-wordpress:hover .plugins{
    visibility: visible;
    opacity: 1 !important;
    transform: scale(1) !important;
    visibility: visible !important;
}
.plugins-theme i{
    background-image: url(../imgs/theme.svg);

}
.plugins-plugin i{
    background-image: url(../imgs/plugin.svg);
}

/* Examples
 * --------------------------------------- */
.examples{
    position: absolute;
    top: -15px;
    left: 5px;
    width: 100%;
    bottom: 0;
    background: #435b71;
    text-align: center;
    padding-top: 77px;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: block;
    visibility: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;
}
.examples.active{
    opacity: 1;
    top:0;
    left: 0;
    visibility: visible;
    z-index: 9999;
}
.examples-wrapper{
    display: table-cell;
    vertical-align: middle;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
}
.examples-column{
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: calc(25% - 30px);
    min-width: 250px;
    margin: 0 0 40px 0;
}
.examples-column h3{
    font-size: 25px;
    padding: 0 0 20px 5px;
    color: rgba(255,255,255,0.6);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}
.examples-column a{
    font-size: 16px;
    padding: 5px;
    display: inline-block;
    color: #fff;
}

/* Underline From Left */
.examples-column a{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.examples-column a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #fff;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.examples-column a:hover:before,
.examples-column a:focus:before,
.examples-column a:active:before {
  right: 0;
}


/* Close examples
 * --------------------------------------- */
.info-close{
    position: fixed;
    top: 20px;
    right: 48px;
    z-index: 999;
    font-size: 3em;
    cursor: pointer;
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    line-height: 50px;
    display: inline-block;
-webkit-transition: all 530ms cubic-bezier(0.480, 0.030, 0.195, 1); /* older webkit */
-webkit-transition: all 530ms cubic-bezier(0.480, 0.030, 0.195, 1.510);
   -moz-transition: all 530ms cubic-bezier(0.480, 0.030, 0.195, 1.510);
     -o-transition: all 530ms cubic-bezier(0.480, 0.030, 0.195, 1.510);
        transition: all 530ms cubic-bezier(0.480, 0.030, 0.195, 1.510); /* custom */
    transform: scale(0) translateZ(0);
}
.examples.active .info-close{
    transform: scale(1) translateZ(0);
}
.info-close:before,
.info-close:after{
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -25px;
  display: block;
  height: 10px;
  width: 50px;
  background-color: #fff;
  transition: all 0.25s ease-out;
}
.info-close:after {
  transform: rotate(-135deg);
}
.info-close:hover:before,
.info-close:hover:after {
  transform: rotate(0deg);
}

/* Hightlight
 * https://gist.github.com/jbouzekri/80bc2b53fdd62c637e5a
 * --------------------------------------- */
.hll { background-color: #49483e }
/* { background: #272822; color: #f8f8f2 } */
.c { color: #75715e } /* Comment */
.err { color: #960050; background-color: #1e0010 } /* Error */
.k { color: #66d9ef } /* Keyword */
.l { color: #ae81ff } /* Literal */
.n { color: #f8f8f2 } /* Name */
.o { color: #f92672 } /* Operator */
.p { color: #f8f8f2 } /* Punctuation */
.cm { color: #75715e } /* Comment.Multiline */
.cp { color: #75715e } /* Comment.Preproc */
.c1 { color: #75715e } /* Comment.Single */
.cs { color: #75715e } /* Comment.Special */
.ge { font-style: italic } /* Generic.Emph */
.gs { font-weight: bold } /* Generic.Strong */
.kc { color: #66d9ef } /* Keyword.Constant */
.kd { color: #66d9ef } /* Keyword.Declaration */
.kn { color: #f92672 } /* Keyword.Namespace */
.kp { color: #66d9ef } /* Keyword.Pseudo */
.kr { color: #66d9ef } /* Keyword.Reserved */
.kt { color: #66d9ef } /* Keyword.Type */
.ld { color: #e6db74 } /* Literal.Date */
.m { color: #ae81ff } /* Literal.Number */
.s { color: #e6db74 } /* Literal.String */
.na { color: #a6e22e } /* Name.Attribute */
.nb { color: #f8f8f2 } /* Name.Builtin */
.nc { color: #a6e22e } /* Name.Class */
.no { color: #66d9ef } /* Name.Constant */
.nd { color: #a6e22e } /* Name.Decorator */
.ni { color: #f8f8f2 } /* Name.Entity */
.ne { color: #a6e22e } /* Name.Exception */
.nf { color: #a6e22e } /* Name.Function */
.nl { color: #f8f8f2 } /* Name.Label */
.nn { color: #f8f8f2 } /* Name.Namespace */
.nx { color: #a6e22e } /* Name.Other */
.py { color: #f8f8f2 } /* Name.Property */
.nt { color: #f92672 } /* Name.Tag */
.nv { color: #f8f8f2 } /* Name.Variable */
.ow { color: #f92672 } /* Operator.Word */
.w { color: #f8f8f2 } /* Text.Whitespace */
.mf { color: #ae81ff } /* Literal.Number.Float */
.mh { color: #ae81ff } /* Literal.Number.Hex */
.mi { color: #ae81ff } /* Literal.Number.Integer */
.mo { color: #ae81ff } /* Literal.Number.Oct */
.sb { color: #e6db74 } /* Literal.String.Backtick */
.sc { color: #ffc600 /*#e6db74*/ } /* Literal.String.Char */
.sd { color: #e6db74 } /* Literal.String.Doc */
.s2 { color: #e6db74 } /* Literal.String.Double */
.se { color: #ae81ff } /* Literal.String.Escape */
.sh { color: #e6db74 } /* Literal.String.Heredoc */
.si { color: #e6db74 } /* Literal.String.Interpol */
.sx { color: #e6db74 } /* Literal.String.Other */
.sr { color: #e6db74 } /* Literal.String.Regex */
.s1 { color: #e6db74 } /* Literal.String.Single */
.ss { color: #e6db74 } /* Literal.String.Symbol */
.bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
.vc { color: #f8f8f2 } /* Name.Variable.Class */
.vg { color: #f8f8f2 } /* Name.Variable.Global */
.vi { color: #f8f8f2 } /* Name.Variable.Instance */
.il { color: #ae81ff } /* Literal.Number.Integer.Long */

/* Custom slide arrows
 * --------------------------------------- */
.my-arrow svg {
  padding: 5px;
}

.my-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -45px;
  width: 70px;
  height: 90px;
  z-index: 99;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline:0;
}
.my-arrow.left{
  left: 35px;
}
.my-arrow.right{
  right: 35px;
}
polyline{
    stroke-width: 3;
}
.my-arrow.left:hover polyline,
.my-arrow.left:focus polyline {
  stroke-width: 6;
}
.my-arrow.left:active polyline {
  stroke-width: 10;
  transition: all 100ms ease-in-out;
}
.my-arrow.right:hover polyline,
.my-arrow.right:focus polyline {
  stroke-width: 6;
}
.my-arrow.right:active polyline {
  stroke-width: 10;
  transition: all 100ms ease-in-out;
}
polyline {
  transition: all 250ms ease-in-out;
}